import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/lecture-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const LecturerModule = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"Lecturer"} banner={banner} />

        <SectionTabs />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text font-size-6">
              The lecturer portal is built to ensure that all your lecturers can
              innovatively track their tasks and responsibilities. This module
              is developed to help one of the most critical stakeholders of your
              institution, the lecturers, ensure that all their schedules are
              managed conveniently based on their skills, experiences and
              availability. It presents a 360-degree overview for teachers to
              mark and declare exams and assessments efficiently. It promotes
              communication between the lecturer and other parties, such as
              approving student leaves and allows the lecturer to apply for
              requests conveniently too.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.Lecturer} />
        <Features data={FeatureData.Lecturer} />
        <ModuleImage />
        <Benefits data={BenefitData.Lecturer} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default LecturerModule;
